<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {
    // 
  },
  data: () => ({
    //
  }),
};
</script>

<style>
.obligatorio{
  color: red;
  margin-left: 2px;
}
</style>
