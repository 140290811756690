import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    // Configuración del plugin de persistencia
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key), // Obtener datos del almacenamiento local
        setItem: (key, value) => ls.set(key, value), // Establecer datos del almacenamiento local
        removeItem: (key) => ls.remove(key), // Eliminar datos del almacenamiento local
      },
    }),
  ],

  state: {
    // Estado inicial de la aplicación
    token: null, // Token de autenticación
    user: {}, // Información del usuario
    movil: "", // Número de teléfono móvil
    currentServices: [], // Lista de servicios actuales
    currentPatient: null, // Información del paciente actual
    currentPatientDoc:null, // Documente del paciente actual
    currentService: null, // Información del servicio actual
    currentPatientInfo: {}, // Información del paciente actual
    currentConsecutives: {}, // Información de los consecutivos
    historiasClinicas: {}, // Historias clínicas
  },

  mutations: {
    // Mutaciones para actualizar el estado
    token(state, value) {
      state.token = value;
    },
    user(state, value) {
      state.user = value;
    },
    movil(state, value) {
      state.movil = value;
    },
    currentServices(state, value) {
      state.currentServices = value;
    },
    currentPatient(state, value) {
      state.currentPatient = value;
    },
    setDatosGuardadosDB(state,{data_hc,currentService}){
      if (!state.historiasClinicas[currentService]) {
        state.historiasClinicas[currentService] = {};
        state.historiasClinicas[currentService]['guardado_db'] = data_hc;
      }else{
        state.historiasClinicas[currentService]['guardado_db'] = data_hc;
      }
    },
    currentService(state, value) {
      state.currentService = value;
    },
    patientInfo(state, value) {
      state.currentPatientInfo = value;
    },
    consecutiveInfo(state, value) {
      state.currentConsecutives = value;
    },
    cerrarSesion(state){
      state.token = null;
      state.user = {};
      location.reload();
    },
    initHistoriaClinica(state, consecutivo) {
      // Inicializa una historia clínica si no existe
      if (!state.historiasClinicas[consecutivo]) {
        state.historiasClinicas[consecutivo] = {};
      }
    },
    setKeyToHistory(state, value) {
      // Establece una clave y su valor en la historia clínica
      if (value.validate) {
        if (!state.historiasClinicas[value.consecutivo][value.key]) {
          state.historiasClinicas[value.consecutivo][value.key] = value.value;
        }
      } else {
        state.historiasClinicas[value.consecutivo][value.key] = value.value;
      }
    },
  },

  actions: {},
  modules: {},
});
