import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "../store";
Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'LoginApp',
        component: () => import(/* webpackChunkName: "about" */ '../modules/general/LoginApp.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../modules/general/LoginApp.vue')
    },
    {
        path: '/consecutivos',
        meta: {
            requiresAuth: true,
          },
        name: 'ListarConsecutivos',
        component: () => import(/* webpackChunkName: "about" */ '../modules/consecutivos/ListarConsecutivos.vue')
    },
    {
        path: '/paciente',
        meta: {
            requiresAuth: true,
          },
        name: 'DatosPaciente',
        component: () => import(/* webpackChunkName: "about" */ '../modules/paciente/DatosPaciente.vue')
    },
    {
        path: '/antecedentes',
        meta: {
            requiresAuth: true,
          },
        name: 'AntecedentesPaciente',
        component: () => import(/* webpackChunkName: "about" */ '../modules/paciente/AntecedentesPaciente.vue')
    },
    {
        path: '/signos-vitales',
        meta: {
            requiresAuth: true,
          },
        name: 'SignosVitales',
        component: () => import(/* webpackChunkName: "about" */ '../modules/paciente/SignosVitales.vue')
    },
    {
        path: '/diagnostico',
        meta: {
            requiresAuth: true,
          },
        name: 'ConclusionDiagnostico',
        component: () => import(/* webpackChunkName: "about" */ '../modules/paciente/ConclusionDiagnostico.vue')
    },
    {
        path: '*',
        name: '',
        component: () => import(/* webpackChunkName: "about" */ '../modules/general/LoginApp.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      if (store.state.token != null) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  });

export default router
